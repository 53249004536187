.loading {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.layout {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
}
