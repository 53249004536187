#root {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: var(--color-bg-1);

  -webkit-app-region: drag;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (hover: none) {
  .arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
    background-color: var(--color-secondary);
    color: var(--color-text-2);
    border: 1px solid transparent;
  }
  .arco-btn-primary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
    background-color: rgb(var(--primary-6));
    color: #fff;
    border: 1px solid transparent;
  }
  .arco-btn-text.arco-btn-status-danger:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
    background-color: transparent;
    color: rgb(var(--danger-6));
    border-color: transparent;
  }
  .arco-menu-item:hover {
    background-color: var(--color-menu-light-bg) !important;
    color: var(--color-text-2) !important;
  }
  .arco-menu-item.arco-menu-selected:hover {
    background-color: var(--color-fill-2) !important;
    color: rgb(var(--primary-6)) !important;
  }
}
