.main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main .result {
  margin: 4px 16px;
  color: var(--color-text-3);
  font-size: 12px;
}
.main .result .total {
  color: rgb(var(--red-6));
  margin: 0 4px;
}
