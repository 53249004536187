.content .top {
  display: flex;
  justify-content: space-between;
}
.content .top .left {
  flex: 1;
}
.content .top .left .main {
  display: flex;
  align-items: center;
  padding: 8px 0 0 0;
}
.content .top .left .main .name {
  display: block;
  font-weight: 500;
  font-size: 18px;
  margin-right: 4px;
  line-height: 1.3;
}
.content .top .left .main .name .text {
  margin-right: 4px;
}
.content .top .left .main .name .status {
  margin-right: 2px;
  font-size: 12px;
}
.content .top .left .main .name .icon {
  font-size: 14px;
  margin-right: 2px;
  stroke-width: 3px;
}
.content .top .left .main .name .addr {
  font-size: 14px;
  font-weight: normal;
  margin-right: 4px;
}
.content .top .left .info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 6px 0 4px;
}
.content .top .left .info .stock {
  margin: 2px 2px 2px 0;
}
.content .top .left .info .icon {
  margin: 2px 2px 2px 0;
  stroke-width: 3px;
}
.content .top .left .info .addr {
  margin: 2px 4px 2px 0;
}
.content .top .left .info .status {
  margin: 2px 0;
  font-size: 12px;
}
.content .top .right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 4px;
  padding: 8px 0;
}
.content .top .right .date {
  text-align: right;
}
.content .top .right .share {
  padding: 6px 0 0;
  display: flex;
  align-items: flex-end;
  line-height: 1;
}
.content .top .right .share .capital {
  font-size: 22px;
  color: rgb(var(--red-5));
  font-weight: 500;
  margin: 0 2px -2px;
}
.content .top .right .share .unit {
  color: rgb(var(--red-5));
  font-weight: 500;
}
.content .bottom .item {
  position: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4px;
  font-size: 11px;
}
.content .bottom .item .label {
  color: var(--color-text-2);
  padding: 2px 0;
}
.content .bottom-big {
  font-size: 12px;
}
.content .bottom-big .item {
  display: flex;
  align-items: center;
  padding: 4px 0;
}
.content .bottom-big .item .label {
  color: var(--color-text-2);
  margin-right: 8px;
}
