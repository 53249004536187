.unknown {
  padding: 12px 16px;
}
.unknown .top {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
}
.unknown .top .name {
  font-size: 20px;
  font-weight: 500;
  margin-right: 4px;
}
.unknown .top .time {
  margin: 2px 0;
  color: var(--color-text-3);
  text-align: right;
}
.unknown .desc {
  margin: 12px 0 4px;
}
.unknown .desc-inline tr {
  vertical-align: top !important;
}
