.info {
  background-color: var(--color-bg-1);
  height: 100%;
  display: flex;
  flex-direction: column;
}
.info .title-bar {
  flex: none;
  position: relative;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 4px 0;
  height: 46px;
}
.info .title-bar .title {
  flex: 1;
  width: 0;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
}
.info .title-bar .title .text {
  display: inline-flex;
  align-items: center;
}
.info .title-bar .title .text .status {
  margin-left: 4px;
}
.info .title-bar .left {
  position: absolute;
  height: 46px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-left: 8px;
  left: 0;
  top: 0;
  bottom: 0;
}
.info .title-bar .right {
  position: absolute;
  height: 46px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-right: 8px;
  right: 0;
  top: 0;
  bottom: 0;
}
.info .icon-btn {
  width: 32px;
  height: 32px;
  font-size: 20px;
  margin: 0 2px;
  color: var(--color-text-3);
  -webkit-app-region: no-drag;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
@media (hover: hover) and (pointer: fine) {
  .info .icon-btn:hover {
    background-color: var(--color-fill-2);
  }
}
.info .content {
  flex: 1;
  height: 0;
}
.info .content .tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.info .content .tabs :global .arco-tabs-content {
  padding-top: 0px;
  height: 100%;
  overflow-y: auto;
}
.info .tabs-skeleton :global .arco-tabs-header-ink {
  background-color: unset;
}
