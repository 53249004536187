.content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 12px 0 128px;
}
.content .box {
  width: 100%;
  max-width: 300px;
  padding: 18px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content .box .title {
  text-align: center;
  margin: 8px 0 24px;
}
.content .box .handle {
  margin: 6px 0;
}
.content .box .password {
  margin: 6px 0;
}
.content .box .login-button {
  width: 100%;
  margin-top: 36px;
}
