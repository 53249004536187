.fh-scrollbar-y {
  overflow-y: scroll;
}
@supports (overflow-y: overlay) {
  .fh-scrollbar-y {
    overflow-y: overlay;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .fh-scrollbar-y {
      overflow-y: scroll;
    }
  }
}
@media (hover: hover) and (pointer: fine) {
  @supports (overflow-y: overlay) {
    .fh-scrollbar-y {
      overflow-y: hidden;
    }
    @media not all and (min-resolution: 0.001dpcm) {
      .fh-scrollbar-y {
        overflow-y: scroll;
      }
    }
  }
  .fh-scrollbar-y:hover {
    overflow-y: scroll;
  }
  @supports (overflow-y: overlay) {
    .fh-scrollbar-y:hover {
      overflow-y: overlay;
    }
    @media not all and (min-resolution: 0.001dpcm) {
      .fh-scrollbar-y:hover {
        overflow-y: scroll;
      }
    }
  }
}
.fh-scrollbar-x {
  overflow-x: scroll;
}
@supports (overflow-x: overlay) {
  .fh-scrollbar-x {
    overflow-x: overlay;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .fh-scrollbar-x {
      overflow-x: scroll;
    }
  }
}
@media (hover: hover) and (pointer: fine) {
  @supports (overflow-x: overlay) {
    .fh-scrollbar-x {
      overflow-x: hidden;
    }
    @media not all and (min-resolution: 0.001dpcm) {
      .fh-scrollbar-x {
        overflow-x: scroll;
      }
    }
  }
  .fh-scrollbar-x:hover {
    overflow-x: scroll;
  }
  @supports (overflow-x: overlay) {
    .fh-scrollbar-x:hover {
      overflow-x: overlay;
    }
    @media not all and (min-resolution: 0.001dpcm) {
      .fh-scrollbar-x:hover {
        overflow-x: scroll;
      }
    }
  }
}
