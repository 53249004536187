.share {
  padding: 12px 16px;
}
.share .desc {
  margin: 0 0 8px;
}
.share .desc .price {
  color: rgb(var(--red-6));
  font-weight: 500;
}
.share .desc-inline tr {
  vertical-align: top !important;
}
.share .share-structure-list .item {
  padding-left: 0px;
}
.share .title {
  font-weight: 500;
  font-size: 18px;
  margin-right: 4px;
  margin-bottom: 8px;
}
