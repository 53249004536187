.other-unlock-list {
  padding: 12px 16px;
}
.other-unlock-list .unlock-list .title {
  font-weight: 500;
  font-size: 18px;
  margin-right: 4px;
  margin-bottom: 8px;
}
.other-unlock-list .unlock-list .header .capital {
  color: rgb(var(--red-6));
  font-weight: 500;
}
