.outer-unlock-list {
  margin: 12px 0 0 0;
}
.outer-unlock-list .title {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.outer-unlock-list .title .main {
  font-weight: 500;
  font-size: 18px;
  margin-right: 4px;
}
.outer-unlock-list .title .sub {
  color: var(--color-text-3);
}
.outer-unlock-list .item {
  position: relative;
  border: 1px solid var(--color-neutral-3);
  border-radius: var(--border-radius-small);
  background: var(--color-bg-2);
  transition: box-shadow 0.2s linear;
  border-radius: var(--border-radius-none);
  margin: 0 0 12px;
  padding: 8px 16px;
}
.outer-unlock-list .item:hover {
  box-shadow: 0 4px 10px rgb(var(--gray-2));
}
.outer-unlock-list .trigger {
  font-weight: 400;
  font-size: 14px;
  margin-left: 4px;
  color: rgb(var(--arcoblue-5));
  cursor: pointer;
}
.unlock-capital {
  color: rgb(var(--red-6));
  font-weight: 500;
}
