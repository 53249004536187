.stock-executive {
  padding: 12px 16px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.stock-executive .search {
  margin-bottom: 12px;
}
.stock-executive .list .hint {
  color: var(--color-text-3);
  margin: -8px 0 8px;
}
.stock-executive .list .item .content {
  padding: 8px;
}
.stock-executive .empty {
  height: 100%;
}
.stock-executive .empty :global .arco-empty-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
}
.stock-executive .title {
  font-weight: 500;
  font-size: 18px;
  margin-right: 4px;
  margin-bottom: 8px;
}
.header {
  display: flex;
  align-items: center;
}
.header .name {
  line-height: 1.3;
  font-weight: 500;
  font-size: 18px;
  margin-right: 4px;
}
.header .male :global path {
  fill: rgb(var(--blue-6));
  stroke-width: 40px;
  stroke: rgb(var(--blue-6));
}
.header .female :global path {
  fill: rgb(var(--red-6));
  stroke-width: 24px;
  stroke: rgb(var(--red-6));
}
.header .age {
  margin: 0 4px;
}
