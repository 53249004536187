.menu {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.menu .header {
  height: 80px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu .content {
  height: 0;
  flex: 1;
}
.menu .footer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
