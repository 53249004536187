.search-bar {
  display: flex;
  box-sizing: border-box;
  background-color: var(--color-bg-1);
  padding: 10px 0;
}
.search-bar .icon-btn {
  min-width: 32px;
  font-size: 20px;
  color: var(--color-text-3);
  -webkit-app-region: no-drag;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 12px;
  transition: background-color 0.2s ease-in-out;
}
@media (hover: hover) and (pointer: fine) {
  .search-bar .icon-btn:hover {
    background-color: var(--color-fill-2);
  }
}
.search-bar .search-input {
  -webkit-app-region: no-drag;
}
