.content {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.content .list {
  height: 100%;
}
.content .empty {
  height: 100%;
}
.content .empty :global .arco-empty-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
}
