.content {
  display: flex;
  justify-content: space-between;
}
.content .left {
  flex: 1;
}
.content .left .main {
  display: flex;
  align-items: center;
  padding: 8px 0 0 0;
}
.content .left .main .name {
  line-height: 1.3;
  font-weight: 500;
  font-size: 18px;
  margin-right: 4px;
}
.content .left .info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 6px 0 4px;
}
.content .left .info .stock {
  margin: 2px 2px 2px 0;
}
.content .left .info .icon {
  margin: 2px 2px 2px 0;
  stroke-width: 3px;
}
.content .left .info .addr {
  margin: 2px 4px 2px 0;
}
.content .left .info .status {
  margin: 2px 0;
  font-size: 12px;
}
.content .right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 4px;
  padding: 8px 0;
}
.content .right .date {
  text-align: right;
}
.content .right .share {
  padding: 6px 0 0;
  display: flex;
  align-items: flex-end;
  line-height: 1;
}
.content .right .share .capital {
  font-size: 22px;
  color: rgb(var(--red-5));
  font-weight: 500;
  margin: 0 2px -2px;
}
.content .right .share .unit {
  color: rgb(var(--red-5));
  font-weight: 500;
}
