.tag {
  display: inline-block;
  line-height: 1;
  padding: 3px;
  border-radius: var(--border-radius-small);
  font-size: 10px;
  box-sizing: border-box;
  font-weight: 500;
  vertical-align: middle;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
