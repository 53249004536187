.unlock-date {
  display: flex;
  align-items: center;
}
.unlock-date .text {
  margin-right: 4px;
}
.unlock-date .fav {
  cursor: pointer;
}
.unlock-capital {
  color: rgb(var(--red-6));
  font-weight: 500;
}
.external-link {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.intro {
  white-space: pre-line;
}
.titles {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2px;
}
.titles .title {
  margin: 2px 6px 2px 0px;
  font-size: 12px;
}
