.basic {
  padding: 12px 16px;
}
.basic .top {
  display: flex;
  align-items: baseline;
}
.basic .top .title {
  flex: 1;
  display: flex;
  align-items: baseline;
}
.basic .top .title .name {
  font-size: 20px;
  font-weight: 500;
}
.basic .top .title .gender {
  margin-left: 8px;
}
.basic .top .title .age {
  margin-left: 8px;
  margin-right: 4px;
}
.basic .top .time {
  flex: none;
  color: var(--color-text-3);
  text-align: right;
}
.basic .desc {
  margin: 12px 0 4px;
}
.basic .desc-inline tr {
  vertical-align: top !important;
}
