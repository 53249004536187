.company {
  padding: 12px 16px;
}
.company .top {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
}
.company .top .name {
  font-size: 20px;
  font-weight: 500;
  margin-right: 4px;
}
.company .top .time {
  margin: 2px 0;
  color: var(--color-text-3);
  text-align: right;
}
.company .desc {
  margin: 12px 0 4px;
}
.company .desc-inline tr {
  vertical-align: top !important;
}
.company .item {
  border-top: 1px solid var(--color-border-2);
  margin-bottom: 2px;
  display: flex;
  flex-direction: column;
}
.company .item .jump {
  margin: -8px 0 8px;
}
.company .item .tags {
  margin: -4px 0 4px;
  display: flex;
  flex-wrap: wrap;
}
.company .item .tags .edu {
  font-size: 11px;
  margin: 0 6px 6px 0;
  padding: 4px;
}
.company .name .inline-name {
  display: inline-flex;
  align-items: center;
}
.company .name .inline-name .name {
  margin-right: 8px;
}
.company .name .tags {
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
}
.company .name .tags .edu {
  font-size: 11px;
  margin: 0 6px 6px 0;
  padding: 4px;
}
.company .trigger {
  font-weight: 400;
  font-size: 14px;
  margin-left: 4px;
  color: rgb(var(--arcoblue-5));
  cursor: pointer;
}
.company .title {
  margin: 12px 0 12px 0;
  display: flex;
  align-items: center;
}
.company .title .main {
  font-weight: 500;
  font-size: 18px;
  margin-right: 4px;
}
.company .title .sub {
  color: var(--color-text-3);
}
