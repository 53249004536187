.inner-unlock-list {
  border-bottom: 1px solid var(--color-border-2);
  margin: 12px 0 0 0;
}
.inner-unlock-list .title {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.inner-unlock-list .title .main {
  font-weight: 500;
  font-size: 18px;
  margin-right: 4px;
}
.inner-unlock-list .title .sub {
  color: var(--color-text-3);
}
.inner-unlock-list .item {
  border-top: 1px solid var(--color-border-2);
  margin-bottom: 2px;
}
.inner-unlock-list .desc {
  margin: 12px 0 4px;
}
.inner-unlock-list .desc .intro {
  white-space: pre-line;
}
.inner-unlock-list .desc-inline tr {
  vertical-align: top !important;
}
.inner-unlock-list .trigger {
  font-weight: 400;
  font-size: 14px;
  margin-left: 4px;
  color: rgb(var(--arcoblue-5));
  cursor: pointer;
}
.unlock-capital {
  color: rgb(var(--red-6));
  font-weight: 500;
}
