.content .top {
  display: flex;
  justify-content: space-between;
}
.content .top .left {
  flex: 1;
}
.content .top .left .main {
  display: flex;
  align-items: center;
  padding: 8px 0 0 0;
}
.content .top .left .main .name {
  line-height: 1.3;
  font-weight: 500;
  font-size: 18px;
  margin-right: 4px;
}
.content .top .left .main .male :global path {
  fill: rgb(var(--blue-6));
  stroke-width: 40px;
  stroke: rgb(var(--blue-6));
}
.content .top .left .main .female :global path {
  fill: rgb(var(--red-6));
  stroke-width: 24px;
  stroke: rgb(var(--red-6));
}
.content .top .left .main .titles {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
.content .top .left .main .titles .title {
  margin: 2px 0 2px 6px;
  font-size: 11px;
}
.content .top .left .info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 6px 0 4px;
}
.content .top .left .info .stock {
  margin: 2px 2px 2px 0;
}
.content .top .left .info .icon {
  margin: 2px 2px 2px 0;
  stroke-width: 3px;
}
.content .top .left .info .addr {
  margin: 2px 4px 2px 0;
}
.content .top .left .info .status {
  margin: 2px 0;
  font-size: 12px;
}
.content .top .right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 4px;
  padding: 8px 0;
}
.content .top .right .date {
  text-align: right;
}
.content .top .right .share {
  padding: 6px 0 0;
  display: flex;
  align-items: flex-end;
  line-height: 1;
}
.content .top .right .share .capital {
  font-size: 22px;
  color: rgb(var(--red-5));
  font-weight: 500;
  margin: 0 2px -2px;
}
.content .top .right .share .unit {
  color: rgb(var(--red-5));
  font-weight: 500;
}
.content .tags {
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
}
.content .tags .edu {
  font-size: 11px;
  margin: 0 6px 6px 0;
  padding: 4px;
}
.content .tags .org {
  font-size: 11px;
  padding: 4px;
  margin: 0 6px 6px 0;
}
