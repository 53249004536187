.filter {
  height: 100%;
  background-color: var(--color-bg-1);
  padding: 8px 16px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.filter .content {
  height: 0;
  flex: 1;
  overflow-y: auto;
  padding: 12px 0;
}
.filter .content .title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
}
.filter .content .options {
  display: flex;
  flex-wrap: wrap;
}
.filter .content .options .tag {
  margin: 0 10px 10px 0;
  min-width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 6px 12px;
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--color-text-2);
  background-color: var(--color-fill-2);
  cursor: pointer;
}
.filter .content .options .tag-checked {
  background-color: rgb(var(--arcoblue-1));
  color: rgb(var(--arcoblue-5));
}
.filter .content .capital {
  display: flex;
  align-items: center;
}
.filter .content .capital .input {
  margin-right: 10px;
  max-width: 150px;
}
.filter .content .capital .text {
  margin-right: 10px;
}
.filter .content .location {
  display: flex;
  align-items: center;
}
.filter .content .location .province {
  max-width: 150px;
  margin-right: 10px;
}
.filter .content .location .city {
  max-width: 150px;
}
.filter .content .stock .input {
  max-width: 150px;
}
.filter .footer {
  margin-top: 16px;
}
.filter .footer .btn-group {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter .footer .btn-group .btn {
  min-width: 100px;
  margin: 0 12px;
}
